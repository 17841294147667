import Appsignal from '@appsignal/javascript'
import type { HashMap, HashMapValue } from '@appsignal/types'
import { plugin as pluginWindowEvents } from '@appsignal/plugin-window-events'
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator'
import { plugin as pluginBreadcrumbsConsole } from '@appsignal/plugin-breadcrumbs-console'
import { plugin as pluginBreadcrumbsNetwork } from '@appsignal/plugin-breadcrumbs-network'

export type ExceptionMetaInput = Record<
  string,
  string | number | null | undefined
>
export type ExceptionParams = HashMap<HashMapValue>

export class Exceptions {
  private client?: Appsignal

  constructor() {
    const key = process.env.APPSIGNAL_FRONTEND_KEY
    const revision = process.env.HEROKU_SLUG_COMMIT
    if (!key || process.env.NODE_ENV === 'test') return

    this.client = new Appsignal({ key, revision })
  }

  public setup() {
    if (!this.client || typeof window === 'undefined') return

    this.client.use(pluginWindowEvents())
    this.client.use(pluginPathDecorator())
    this.client.use(pluginBreadcrumbsConsole())
    this.client.use(pluginBreadcrumbsNetwork())
    this.client.addDecorator((span) => span.setTags(this.metaTags()))
  }

  public handle(
    error: Error,
    {
      params,
      tags,
    }: { params?: ExceptionMetaInput; tags?: ExceptionMetaInput } = {}
  ) {
    if (window.store?.debug) {
      console.error(error, { params })
    }

    if (!this.client) return

    this.client.sendError(error, (span) => {
      if (params) span.setParams(params as ExceptionParams)
      if (tags) span.setTags(tags as HashMap<string>)
    })
  }

  public handleCustom(
    name: string,
    {
      message,
      ...meta
    }: {
      message?: string
      params?: ExceptionMetaInput
      tags?: ExceptionMetaInput
    } = {}
  ) {
    if (!this.client) return

    const error = new Error(message)
    error.name = name
    this.handle(error, meta)
  }

  public addBreadcrumb(args: Parameters<Appsignal['addBreadcrumb']>[0]) {
    if (!this.client) return

    this.client.addBreadcrumb(args)
  }

  private metaTags() {
    const tags = {} as HashMap<string>
    const user_id = window.store?.currentUser?.id
    const org_id = window.store?.currentUser?.org?.id
    const smartlook = window.smartlook?.sessionId

    if (user_id) tags['user_id'] = user_id
    if (org_id) tags['org_id'] = org_id
    if (smartlook) tags['smartlook_session_id'] = smartlook
    return tags
  }
}

export const exceptions = new Exceptions()
