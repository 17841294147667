/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

type RegisteredEvent = { name: string }

export const onRegister = (fn: (name: string) => void) => {
  if (typeof window === 'undefined') return
  const listener = (e: CustomEvent<RegisteredEvent>) => {
    fn(e.detail.name)
  }
  window.addEventListener('component-registered', listener as EventListener)
}

export const registry = new Map()
export const registerComponents = (
  components: Record<string, React.ComponentType<any>>
) => {
  Object.entries(components).forEach(([name, component]) =>
    registerComponent(name, component)
  )
}

const registerComponent = (
  name: string,
  component: React.ComponentType<any>
) => {
  if (registry.has(name)) {
    console.warn(`Registering component ${name} already registered`)
  }
  registry.set(name, component)
  if (typeof window !== 'undefined') {
    const evt = new CustomEvent<RegisteredEvent>('component-registered', {
      detail: { name },
    })
    window.dispatchEvent(evt)
  }
}
